<template>
  <div>
    <CModal :show.sync="op_confirm.show" :centered="true" :title="$t(op_confirm.title) + ` [ID: ${op_confirm.value}]`" size="lg">
      {{ $t(op_confirm.message) }}
      <template #footer>
        <CButton type="button" class="mr-2" :color="op_confirm.color_yes || 'primary'" @click="onModalConfirmed(op_confirm.response_yes || true)">{{ $t(op_confirm.yes || 'Yes') }}</CButton>
        <CButton v-if="op_confirm.no" type="button" :color="op_confirm.color_no || 'default'" @click="onModalConfirmed(op_confirm.response_no || false)">{{ $t(op_confirm.no || 'No') }}</CButton>
        <CButton v-if="op_confirm.cancel !== false" type="button" :color="op_confirm.color_cancel || 'secondary'" @click="onModalConfirmed(op_confirm.response_cancel)">{{ $t('Cancel') }}</CButton>
      </template>
    </CModal>

    <CModal :show.sync="modal.show" :centered="true" :title="$t(modal.title)" size="xl">
      <ACard :key="modal.show" :title="modal.header">
        <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
          <!--AMultiSelect :horizontal="{ input: 'col-sm-8 col-lg-4' }" name="shift_id" label="Shift" v-model="form.shift_id" :options="[]" aisValid="isValid('shift_id')" aerrors="getErrors('shift_id')" /-->
          <div class="row">
            <div class="col-sm-6">
              <AInputCounter :disabled="!modal.concurrency" name="concurrency" label="Concurrency" v-model="form.concurrency" :min="modal.concurrency ? 1 : 0" :max="modal.concurrency" @change="value => onValidateShift(value)" />
            </div>
            <div class="col-sm-6 pt-2">
              <small>({{ $t('Available') }}: {{ modal.concurrency }})</small>
            </div>

            <div class="col-sm-6">
              <AInputDatetime name="min_time" label="Min time" type="time" v-model="form.min_time" :use12-hour="false" :minute-step="5" :min-datetime="modal.min_datetime" :max-datetime="modal.max_datetime" format="hh:mm a" value-zone="America/Bogota" zone="America/Bogota" :isValid="isValid('min_time')" :errors="getErrors('min_time')" @input="onMinTimeChange" />
            </div>
            <div class="col-sm-6">
              <AInputDatetime :disabled="true" name="max_time" label="Max time" type="time" v-model="form.max_time" :use12-hour="false" :minute-step="5" format="hh:mm a" value-zone="America/Bogota" zone="America/Bogota" :isValid="isValid('max_time')" :errors="getErrors('max_time')" @close="clearErrors('max_time')" />
            </div>
          </div>

          <ACard v-if="config.reminders" title="Reminders">
            <!--AInputDatetime name="reminder" label="Date" type="date" v-model="form.reminder" :min-datetime="min_date.format('YYYY-MM-DD')" :max-datetime="max_date.format('YYYY-MM-DD')" aisValid="isValid('min_time')" aerrors="getErrors('min_time')" /-->
            <div v-for="(reminder, index) of form.reminders" :key="'reminder' + index" class="row no-gutters">
              <div class="col-sm-6">
                <AInputDatetime label="Date" type="date" :name="'reminder' + index" v-model="reminder.date" :array="index" :remove="onRemoveReminder" :min-datetime="min_date.format('YYYY-MM-DD')" :max-datetime="max_date.format('YYYY-MM-DD')" value-zone="local" zone="local" aisValid="isValid(item_name + index)" aerrors="errors(item_name + index)" />
              </div>
              <div class="col-sm-6">
                <AMultiSelect :name="'recipients' + index" label="Recipients" v-model="reminder.recipients" :options="recipients" :multiple="true" />
              </div>
            </div>

            <div class="text-right">
              <CButton class="text-right" @click="onAddReminder"><CIcon name="cil-plus"/></CButton>
            </div>
          </ACard>
        </CForm>
      </ACard>

      <template #footer>
        <CButton v-if="form.id" type="button" color="danger" @click="onRemoveEvent">{{ $t('Delete') }}</CButton>
        <CButton :disabled="!form.min_time || !form.max_time" type="button" :color="form.id ? 'info' : 'primary'" @click="onAddEvent">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
        <CButton type="button" color="secondary" @click="onCancel">{{ $t('Cancel') }}</CButton>
      </template>
    </CModal>

    <body class="c-light-theme p-3">
      <FullCalendar ref="calendar" :options="calendarOptions" :key="'KAL' + calendar_key" />
    </body>
  </div>
</template>

<script>
//import { CTooltip } from '@coreui/vue'
//import Tooltip from 'tooltip.js'
import formMixin from '@/app/views/_mixins/form-mixin'
import esLocale from '@fullcalendar/core/locales/es'
import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'

export default {
  name: 'Calendar',
  mixins: [formMixin],
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  props: {
    config: {
      type: Object,
      required: true
    },
    availability: {
      type: Object,
      required: true
    },
    reservation: {
      type: Object,
      required: true
    },
    reservations: {
      type: Array,
      required: true
    }
  },
  computed: {
    calendar() {
      return this.$refs.calendar.getApi()
    }
  },
  watch: {
    reservation(val) {
      console.log('Watch', val)
      this.onEditEvent(val)
    },
    reservations(val) {
      //this.reservations = val
      //console.log('Watch', val)
      this.loadCalendar()
      this.loadReservations(val, true)
    }
  },
  data() {
    return {
      shift_time: 60,
      calendar_key: 0,
      calendarOptions: {
        plugins: [timeGridPlugin, interactionPlugin],
        themeSystem: 'standard', // 'bootstrap5',
        initialView: 'timeGridWeek',

        headerToolbar: {
          left: 'timeGridDay,timeGridWeek',
          center: 'title',
          right: 'prev,next'
        },

        firstDay: 1,
        allDaySlot: false,
        locale: esLocale,
        //height: 'auto',
        dateClick: this.handleDateClick,
        //expandRows: true,

        //dayMinWidth: 300,
        //stickyFooterScrollbar: true,
        dayHeaderFormat: { month: 'short', weekday: 'long', day: 'numeric' },

        slotDuration: '00:15',
        slotLabelInterval: '00:15',
        slotEventOverlap: false,

        slotLabelFormat: data => this.getSlotLabelFormat(data),
        /*slotLabelClassNames: function(args) {
          console.log('slotLabelClassNames', args)
        }*/

        height: 70 * (this.config.size || 12), // TODO: Calculate based on number of row shifts
        //contentHeight: 500,
        //aspectRatio: 1.8,
        //eventMinHeight: 50,
        expandRows: true,
        timeZone: 'UTC',
        //nowIndicator: true,

        eventClick: info => this.eventClick(info),

        eventDidMount: function(info) {
          //if (eventsExist) {
          //info.el.insertAdjacentHTML('afterBegin', '•')
          //}
          if (info.event._def.title !== '+') {
            //const parent = info.el.parentElement
            const parent = document.getElementById(info.el.parentNode.id)
            //console.log('TRUE - HERE')
            //console.log(info.el.parentElement)
            //console.log(info.el.parentNode)
            //console.log(document.defaultView.getComputedStyle(parent, null).getPropertyValue('inset'))
            //console.log(info.el.parentNode)
            //console.log(parent)
            /*console.log(parent.style.getPropertyValue('background'))

            parent.style.setProperty('inset', '0px 0% -60px 30px')

            parent.style.inset = 'none'*/
            /*console.log(info.el.parentNode.style.inset)
            info.el.parentNode.style.inset = info.el.parentNode.style.inset.replace(' 50%', ' 30px')
            console.log(info.el.parentNode.style.inset)*/
            //document.getElementsByClassName('page-content')[0].classList.toggle('styled');
          }
        },

        eventMaxStack: 2,
        eventDisplay: 'list-item'
        //displayEventTime: false
        /*eventDidMount: function(info) {
          console.log(info)
          const tooltip = new Tooltip(info.el, {
            title: info.event.extendedProps.description,
            trigger: 'hover',
            placement: 'top',
            container: 'body'
          })
        }*/
      },

      form: { concurrency: 0, min_date: '', max_date: '', reminders: [{ id: 0, date: null, recipients: [] }] },

      recipients: [
        { id: 1, name: 'Administración' },
        { id: 2, name: 'Propietarios' }
      ],

      modal: {
        show: false,
        title: 'Test',
        message: 'Message',
        concurrency: 0,
        min_datetime: '',
        max_datetime: ''
      },

      min_date: this.$moment(),
      max_date: this.$moment(),
      instances: {},

      op_confirm: { show: false, title: 'Remove row', message: 'Are you sure you want to delete this row?', value: false }
    }
  },
  mounted() {
    this.loadCalendar()
    this.loadReservations(this.reservations)

    if (this.reservation.id) {
      console.log('EDIT RESERV', this.reservation)
      this.$nextTick(() => {
        this.onEditEvent(this.reservation)
      })
    }

    //console.log(this.config)
    //console.log(this.availability)
  },
  methods: {
    addShiftTime(date) {
      return this.$moment(date).add(this.availability.shift_time, 'minutes')
    },

    onMinTimeChange(value) {
      if (value) {
        this.form.max_time = this.$moment(value, 'HH:mm')
          .add(this.availability.shift_time, 'minutes')
          .format('HH:mm')
      }
    },

    onModalConfirmed(response) {
      this.op_confirm.show = false
      if (response) this.$emit('removeEvent', this.form)
      else this.modal.show = true
    },
    onRemoveEvent() {
      this.modal.show = false
      this.op_confirm.show = true
    },
    onAddEvent() {
      // TODO: Modal must send pre-calculated data

      //const start = this.$moment(this.modal.args.date)
      const start = this.$moment(this.modal.args.date.format('YYYY-MM-DD ') + this.form.min_time)
      //const end = this.addShiftTime(this.modal.args.date)
      const end = this.$moment(this.modal.args.date.format('YYYY-MM-DD ') + this.form.max_time)

      // TODO: Move this to parent
      const event = { title: `Reserva: (${this.form.concurrency}) - ${this.config.building.identifier}`, start: start.format('YYYY-MM-DD HH:mm:ss'), end: end.format('YYYY-MM-DD HH:mm:ss') }

      /*if (this.form.id) {
        const _event = this.calendar.getEventById(this.instances[this.form.id])
        _event.remove() // TODO: Try to update not to remove
      } else {
        this.calendar.addEvent(event) // TODO: This should be done on parent (after backend confirmation)
      }*/

      this.$emit('registerEvent', { event, form: this.form })
      this.modal.show = false
    },
    onCancel() {
      this.modal.show = false
    },

    onValidateShift(value) {
      // TODO: Check max concurrency
      this.form.concurrency = value
      //console.log(this.form)
    },
    onAddReminder() {
      this.form.reminders.push({ id: 0, date: null, recipients: [] })
    },
    onRemoveReminder(index) {
      this.form.reminders.splice(index, 1)
      if (!this.form.reminders.length) this.onAddReminder()
    },
    onEditEvent(_event) {
      //console.log('EDIT', _event)

      // TODO: go through all the _events and find the id
      let event = this.calendarOptions.events.find(item => item.id === _event.id)
      if (event) {
        event = this.calendar.getEvents()[event._id]
        if (event && event._def.extendedProps.reservation && event._def.extendedProps.reservation.id === _event.id) {
          this.eventClick({ event })
        }
      } else {
        this.showAlert('Este evento ya no está disponible', 'danger') // TODO: How to set disabled class ?? // TODO: Use english ?
      }

      /*console.log(_event)

      console.log(this.calendar.getEvents())
      console.log(this.calendar.getEventById(_event._id))*/
    },

    eventClick(info) {
      const date = this.$moment.utc(info.event._instance.range.start)

      // TODO: Single validation ???
      if (info.event._def.title === '+') {
        const args = { date, event: info.event._instance }
        this.handleDateClick(args)
      } else if (info.event._def.title === '=') {
        const args = { date, event: info.event._instance }
        this.handleDateClick(args)
        //this.showAlert('Ya existe un evento, por favor seleccionelo', 'warning') // TODO: find it ?
      } else if (info.event._def.extendedProps.reservation.building_id === this.config.building.id) {
        const args = { date, event: info.event._instance }
        this.handleDateClick(args, true)
      }
    },

    handleDateClick: function(args, on_event) {
      args.now = this.$moment()

      if (args.now.isAfter(args.date.format('YYYY-MM-DD HH:mm:ss'))) {
        this.showAlert('Este turno ya no está disponible', 'danger') // TODO: How to set disabled class ?? // TODO: Use english ?
      } else {
        const min_datetime = this.$moment(args.event.range.start).utc() //.format('HH:mm:ss') //.000-05:00') //.toISOString()
        const max_datetime = this.$moment(args.event.range.end).utc() //.format('HH:mm:ss') //.000-05:00') //.toISOString()

        let concurrency = this.availability.concurrency
        //const _events = this.reservations.filter(item => date.isSame(item.min_date))

        args._end_date = this.$moment(args.date).add(this.availability.shift_time, 'minutes')

        // TODO: if on_event use event (not filter needed ?)
        // TODO: find anyone inside the range ?
        //const _events = this.reservations.filter(item => this.$moment.utc(item.min_date).isSame(args.date))
        const _events = this.reservations.filter(item => this.$moment.utc(item.min_date).isBetween(args.date, args._end_date, 'minutes', '[]')) // By minutes all included

        //this.form = { id: 0, min_time: min_datetime.format('HH:mm'), max_time: max_datetime.format('HH:mm'), concurrency: 1, reminders: [{ id: 0, date: null, recipients: [] }] }
        this.$set(this, 'form', { id: 0, min_time: min_datetime.format('HH:mm'), max_time: max_datetime.format('HH:mm'), concurrency: 1, reminders: [{ id: 0, date: null, recipients: [] }] })

        //this.$set(this, 'form', { id: 0, concurrency: 1, reminders: [null], recipients: [null] })
        let title = 'Create'
        for (const event of _events) {
          //console.log(event, this.config)
          if (event.building_id === this.config.building.id) {
            title = 'Edit'
            //this.form = { id: event.id, min_time: event.min_date, max_time: event.max_date, concurrency: event.concurrency, reminders: event.reminders || [{ id: 0, date: null, recipients: [] }] }
            //this.$set(this, 'form', { id: event.id, concurrency: event.concurrency, reminders: [null], recipients: [null] })
            this.$set(this, 'form', { id: event.id, min_time: event.min_time, max_time: event.max_time, concurrency: event.concurrency, reminders: event.reminders || [{ id: 0, date: null, recipients: [] }] })
          } else {
            concurrency -= event.concurrency
          }
        }

        if (concurrency > 0) {
          this.modal.args = args
          this.modal.concurrency = concurrency
          this.modal.min_datetime = min_datetime.format('HH:mm')
          this.modal.max_datetime = max_datetime.format('HH:mm')

          this.modal.title = `${this.$t(title)} reserva para ${this.config.common_area.name}: ` + args.date.locale('es').format('[el] D [de] MMMM [de] YYYY [a las] hh:mm a') // TODO: Use english ?
          this.modal.header = this.config.building.identifier
          this.modal.show = true
        } else {
          this.showAlert('No hay cupos disponibles para este turno', 'danger') // TODO: How to set disabled class ?? // TODO: Use english ?
        }
      }
      this.max_date = args.date
    },

    loadCalendar() {
      //console.log('LOAD CALENDAR')

      // TODO: config
      const _data = this.availability
      const _start = this.$moment()
      const _date = this.addShiftTime(this.$moment('2000-01-01 00:00:00'))
      const _first = _data.availability[0]
      const _last = _data.availability.slice(-1)
      const _shifts = Object.values(_first.shifts)
      const _first_day_last_shift = _first.date + (_shifts.length ? ` ${_shifts.slice(-1)[0].ends}` : '')

      if (_start.isAfter(_first_day_last_shift)) {
        _start.add(1, 'Day')
      }

      const _events = []
      if (_shifts.length) {
        //const _now = new Date()
        const _now = this.$moment()

        //console.log(_now, new Date())

        // Disable past shifts
        /*if (!_start.isAfter(_first_day_last_shift)) {
          for (const shift of _shifts) {
            const end = new Date(_first.date + ` ${shift.ends}`)
            const start = new Date(_first.date + ` ${shift.init}`)

            // TODO: use end ?
            if (start < _now) {
              this.calendar.addEvent({ title: '', start, end, className: 'empty-event' })
              //this.calendar.addEvent({ title: '+', start, end, className: 'add-event' })
            }
          }
        }*/

        let is_first = true
        for (const day of _data.availability) {
          for (const shift of Object.values(day.shifts)) {
            //const end = new Date(day.date + ` ${shift.ends}`)
            //const start = new Date(day.date + ` ${shift.init}`)
            const end = day.date + ` ${shift.ends}`
            const start = day.date + ` ${shift.init}`

            // TODO: use end ?
            if (is_first && _now.isAfter(start)) {
              //this.calendar.addEvent({ title: '+', start, end, className: 'add-event' })
            } else if (!this.config.disabled) {
              _events.push({ title: '+', start, end, classNames: ['button-event', 'add-event'] })
            }
          }
          is_first = false
        }
      }

      this.calendarOptions.events = _events

      // Disable restricted by conditions

      // TODO: Apply rules
      //const conditions = this.conditions[this.availability.control_type]
      /*console.log(conditions)
      console.log(this.config)
      console.log(this.availability)*/

      // TODO: if now > last shift... start = tomorrow
      //console.log(_start, _first_day_last_shift, _start.isAfter(_first_day_last_shift))

      //console.log('duration', _data.all_day, _data.shift_time, _date.format('hh:mm:ss'))

      const _end = this.$moment(_last.slice(-1)[0].date)

      this.calendarOptions.validRange = {
        start: _start.startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        //end: _start.add(_data.control_days, 'days').format('YYYY-MM-DD HH:mm:ss')
        end: _end.endOf('week').format('YYYY-MM-DD HH:mm:ss')
      }

      this.calendarOptions.hiddenDays = [0, 1, 2, 3, 4, 5, 6, 7].filter(el => !_data.available.includes('' + el))

      if (_data.all_day) {
        this.calendarOptions.slotDuration = '24:00:00'
        this.calendarOptions.slotLabelInterval = '24:00:00'
      } else {
        this.calendarOptions.slotMinTime = _data.min_time
        this.calendarOptions.slotMaxTime = _data.max_time
        this.calendarOptions.slotDuration = _date.format('HH:mm:ss')
        this.calendarOptions.slotLabelInterval = _date.format('HH:mm:ss')
      }

      //this.calendar.addEvent({ title: 'Reservado', start: '2022-04-29 09:30:00', end: '2022-04-29 10:15:00', textColor: 'black', backgroundColor: '#CCC' })
    },

    loadReservations(reservations, refresh) {
      if (refresh) {
        // TODO: Find a better way
        //for (const event of this.calendar.getEvents()) event.remove()
      }

      //let id = this.calendar.getEvents().length
      let id = this.calendarOptions.events.length
      //console.log(this.calendar.getEvents())
      //console.log(this.calendarOptions.events)
      //console.log(id)
      for (const reservation of reservations) {
        let classNames = ['event', 'reserved-event']
        //const _event = this.calendar.addEvent({ id: reservation.id, title: reservation.details, start: reservation.min_date, end: reservation.max_date, classNames }) // TODO: Push the events to the config ?

        //console.log(_event.id)
        //this.instances[reservation.id] = _event.id
        if (reservation.building_id === this.config.building.id) {
          classNames = ['event', 'owned-event']
          const index = this.calendarOptions.events.findIndex(item => item.start === reservation.min_date)

          if (index >= 0) {
            this.calendarOptions.events[index].title = '='
            this.calendarOptions.events[index].classNames = ['button-event', 'edit-event']
          }
        }

        console.log(reservation)

        this.calendarOptions.events.push({ _id: id, id: reservation.id, title: reservation.details, start: reservation.min_date, end: reservation.max_date, classNames, extendedProps: { reservation } }) // TODO: Push the events to the config ?
        this.instances[reservation.id] = id++
      }

      this.calendar_key++
    },

    getSlotLabelFormat(data) {
      if (this.availability.shift_time) {
        const date = this.$moment(data.date)
        return date.format('hh:mm a') + ' ~ ' + this.addShiftTime(date).format('hh:mm a')
      }
      return this.$t('All day')
    },

    // Utils
    showAlert(message, type, replace) {
      this.$store.dispatch('setAlert', { message, type: type || 'success', replace })
    }
  }
}
</script>

<style lang="scss">
.event {
  width: 100%;
  border: none;
  padding: 2px;
  //border-color: #ddd;
}
.owned-event {
  color: #000 !important;
  background-color: #4799eb; // TODO: use variable (info)
  cursor: pointer;
}
.reserved-event {
  color: #000 !important;
  background-color: #d16767; // TODO: Use variable (danger)
}
.empty-event * {
  color: #ddd !important;
  background-color: #ddd;
}

/** Fixes **/
.fc-timegrid-slots {
  tbody td {
    min-height: 150px;
  }
}

.fc-day-disabled {
  display: none;
}
.fc-timegrid-event-harness {
  //inset: 0px 0% 0px !important;
  //min-width: 500px;
}

/*//.fc-timegrid-event-harness::after {
.empty-event::after {
  width: 100px;
  color: navy;
  font-weight: bold;
  content: 'test';
}*/

// TODO: use button-event class ?
.button-event {
  width: 25px;
  height: 25px;
  margin: auto 2px;
  line-height: 1em;
  font-size: 1.5em;
  text-align: center;
  border: none;
  cursor: pointer;

  .fc-event-time {
    display: none;
  }
}

.add-event {
  background: #bca24f; // TODO: Use variable (primary)
}

.edit-event {
  background: #4799eb; // TODO: Use variable (info)
}

.fc-timegrid-more-link {
  color: white !important;
  background: #bca24f; // TODO: Use variable (primary)
}
</style>
